define("discourse/plugins/discourse-apim/discourse/templates/user-apim", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-content apim" id="user-content">
    {{#each model.credentials as |credential|}}
      {{#apim-credential
        credential=credential
        showApiKey=(action "showApiKey" credential.product)
        createApiKey=(action "createApiKey" credential.product)
      }}
      {{/apim-credential}}
    {{/each}}
  </div>
  
  
  */
  {
    "id": "5XWU9f8l",
    "block": "[[[10,0],[14,0,\"user-content apim\"],[14,1,\"user-content\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"model\",\"credentials\"]]],null]],null],null,[[[6,[39,2],null,[[\"credential\",\"showApiKey\",\"createApiKey\"],[[30,1],[28,[37,3],[[30,0],\"showApiKey\",[30,1,[\"product\"]]],null],[28,[37,3],[[30,0],\"createApiKey\",[30,1,[\"product\"]]],null]]],[[\"default\"],[[[],[]]]]]],[1]],null],[13],[1,\"\\n\\n\"],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-apim/discourse/templates/user-apim.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"credential\"],false,[\"each\",\"-track-array\",\"apim-credential\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-apim/discourse/templates/user-apim.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});