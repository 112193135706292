define("discourse/plugins/discourse-apim/discourse/templates/admin-plugins-apim", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button {{action "refresh"}} class="btn">Refresh Usage Data</button>
  <button {{action "downloadReport"}} class="btn">Download Usage Report</button>
  */
  {
    "id": "egJuSm1b",
    "block": "[[[11,\"button\"],[24,0,\"btn\"],[4,[38,0],[[30,0],\"refresh\"],null],[12],[1,\"Refresh Usage Data\"],[13],[1,\"\\n\"],[11,\"button\"],[24,0,\"btn\"],[4,[38,0],[[30,0],\"downloadReport\"],null],[12],[1,\"Download Usage Report\"],[13]],[],false,[\"action\"]]",
    "moduleName": "discourse/plugins/discourse-apim/discourse/templates/admin-plugins-apim.hbs",
    "isStrictMode": false
  });
});