define("discourse/plugins/discourse-apim/discourse/routes/group-apim", ["exports", "@ember/routing/route", "@ember/object", "discourse/lib/ajax", "discourse/plugins/discourse-apim/discourse/lib/apim-credential"], function (_exports, _route, _object, _ajax, _apimCredential) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupApimRoute extends _route.default {
    async model() {
      const {
        name
      } = this.modelFor("group");
      const {
        api_keys,
        reporting_subscriptions
      } = await (0, _ajax.ajax)(`/apim/groups/${name}`);
      const credentials = api_keys.map(params => new _apimCredential.ApimCredential(params));
      return {
        name,
        credentials,
        // Handlebars doesn't have a sensible way to tell a field is missing vs an empty list
        // Only admins can see this list
        showReportingSubscriptions: reporting_subscriptions?.length > -1,
        // TODO MRB: why can't I just pass a list here?!
        reportingSubscriptions: (reporting_subscriptions ?? []).map(_ref => {
          let {
            name
          } = _ref;
          return name;
        }).join('\n')
      };
    }
    refreshModel() {
      this.refresh();
    }
    static #_ = (() => dt7948.n(this.prototype, "refreshModel", [_object.action]))();
  }
  _exports.default = GroupApimRoute;
});