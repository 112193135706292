define("discourse/plugins/discourse-apim/discourse/controllers/group-apim", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax"], function (_exports, _controller, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserApimController extends _controller.default {
    async createApiKey(product) {
      await (0, _ajax.ajax)(`/apim/groups/${this.model.name}/products/${product}`, {
        method: 'POST'
      });

      // apparently I have to send an action here rather than just getting the router and calling refresh
      // aren't javascript frameworks wonderful they really make life easy
      this.send("refreshModel");
    }
    static #_ = (() => dt7948.n(this.prototype, "createApiKey", [_object.action]))();
    async showApiKey(product) {
      const {
        primaryKey
      } = await (0, _ajax.ajax)(`/apim/groups/${this.model.name}/products/${product}/showCredentials`, {
        method: 'POST'
      });
      const credential = this.model.credentials.find(credential => credential.product === product);
      credential.apiKey = primaryKey;
    }
    static #_2 = (() => dt7948.n(this.prototype, "showApiKey", [_object.action]))();
    async setReportingSubscriptions(subscriptionNames) {
      await (0, _ajax.ajax)(`/apim/groups/${this.model.name}/reportingSubscriptions`, {
        method: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          subscriptions: subscriptionNames.map(name => ({
            name
          }))
        })
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "setReportingSubscriptions", [_object.action]))();
  }
  _exports.default = UserApimController;
});