define("discourse/plugins/discourse-apim/discourse/lib/apim-credential", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ApimCredential = void 0;
  class ApimCredential {
    static #_ = (() => dt7948.g(this.prototype, "apiKey", [_tracking.tracked]))();
    #apiKey = (() => (dt7948.i(this, "apiKey"), void 0))();
    constructor(_ref) {
      let {
        product,
        displayName,
        enabled,
        usage
      } = _ref;
      this.product = product;
      this.displayName = displayName;
      this.enabled = enabled;
      this.usage = usage;
    }
    callsThisMonth = () => {
      const key = moment().format('YYYY-MM');
      const usageThisMonth = (this.usage ?? []).find(_ref2 => {
        let {
          month
        } = _ref2;
        return month == key;
      });
      return usageThisMonth?.count ?? 0;
    };
  }
  _exports.ApimCredential = ApimCredential;
});