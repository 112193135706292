define("discourse/plugins/discourse-apim/discourse/user-apim-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user",
    path: "u/:username",
    map() {
      this.route("apim");
    }
  };
});