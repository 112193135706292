define("discourse/plugins/discourse-apim/discourse/routes/user-apim", ["exports", "@ember/routing/route", "@ember/object", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/plugins/discourse-apim/discourse/lib/apim-credential"], function (_exports, _route, _object, _ajax, _pluginApi, _apimCredential) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserApimRoute extends _route.default {
    async model() {
      const user = (0, _pluginApi.withPluginApi)("1.31.0", api => api.getCurrentUser());
      const {
        username
      } = user;
      const {
        api_keys
      } = await (0, _ajax.ajax)(`/apim/users/${username}`);
      const credentials = api_keys.map(params => new _apimCredential.ApimCredential(params));
      return {
        user,
        credentials
      };
    }
    refreshModel() {
      this.refresh();
    }
    static #_ = (() => dt7948.n(this.prototype, "refreshModel", [_object.action]))();
  }
  _exports.default = UserApimRoute;
});