define("discourse/plugins/discourse-apim/discourse/templates/connectors/user-main-nav/rcpch-apim-user-main-nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="user.apim">
    {{d-icon "server"}}
    API keys
  </LinkTo>
  */
  {
    "id": "nQIOy1/W",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"user.apim\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"server\"],null]],[1,\"\\n  API keys\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-apim/discourse/templates/connectors/user-main-nav/rcpch-apim-user-main-nav.hbs",
    "isStrictMode": false
  });
});