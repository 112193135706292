define("discourse/plugins/discourse-apim/discourse/routes/admin-plugins-apim", ["exports", "@ember/routing/route", "discourse/lib/ajax"], function (_exports, _route, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsApimRoute extends _route.default {
    // async model() {
    //   const resp = await ajax(`/apim/usage/report.csv`);

    //   console.log({ resp });

    //   return resp;
    // }
  }
  _exports.default = AdminPluginsApimRoute;
});