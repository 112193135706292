define("discourse/plugins/discourse-apim/discourse/templates/connectors/group-reports-nav-item/rcpch-apim-group-reports-nav-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="group.apim">
    API keys
  </LinkTo>
  */
  {
    "id": "1pkUomlH",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"group.apim\"]],[[\"default\"],[[[[1,\"\\n  API keys\\n\"]],[]]]]]],[],false,[\"link-to\"]]",
    "moduleName": "discourse/plugins/discourse-apim/discourse/templates/connectors/group-reports-nav-item/rcpch-apim-group-reports-nav-item.hbs",
    "isStrictMode": false
  });
});